import React from 'react';
import ReactDOM from 'react-dom/client';
import Rotas from './rotas';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Rotas />
);
