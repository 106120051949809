import React from "react";
import './index.css';

function CardEmp(props) {    
    return (
        <div className="card card-e">
           {/* align-items-center */}
            <div className="d-flex"> 
                <div className="img-foto-e">
                    <img className="card-foto-e" alt="foto" src={props.foto} />
                </div>         

                <div className="card-body-e">             

                {props.stwpp === 1 ? (
                    <h6 className="card-title-e"> {props.nome} - <span className="badge" style={{backgroundColor: '#e3f2fd', color: 'black'}}> Enviado - {props.dtwpp}</span> </h6>
                ) : (
                    <h6 className="card-title-e"> {props.nome} </h6>
                )}

                    <p className="card-text-e card-codigo-e">Ramo: {props.ramo} - Avaliação: {props.avaliacao.slice(0, 3)}</p>
                    <p className="card-text-e card-codigo-e"><i className="fas fa-map-marker-alt"></i>Telefone: {props.fone2}</p>
                    <p className="card-text-e card-codigo-e"><i className="fas fa-map-marker-alt"></i>End: {props.endereco}</p>                    
                </div>
            </div>
  
        </div>
    );
}

export default CardEmp;