import React from "react";
import { useNavigate } from 'react-router-dom';
import logoSmall from '../../assets/logo-small.png';
import { Button } from "bootstrap";

function Logout(){
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem('session_iduser');
    localStorage.removeItem('session_usuario');
    navigate('/login');
  }
  return (
    <button onClick={handleLogout} type="button" className="btn btn-outline-danger">Sair</button>
  );
}

function Campanha(){
  const navigate = useNavigate();

  function handleCampanha() {
    navigate('/campanha');
  }
  return (
    <button onClick={handleCampanha} type="button" className="btn btn-outline-success">Campanha</button>
  );
}

function HomeVoltar(){
  const navigate = useNavigate();

  function handleHome() {
    navigate('/home');

  }
  return(
    <button onClick={handleHome} type="button" className="btn btn-outline-success">Voltar</button>
  );
}

function Menu(){
 
    return(
      
        <nav className="navbar fixed-top navbar-expand navbar-dark" style={{backgroundColor: '#e3f2fd'}}>

        <div className="container-fluid">
                
            <a className="navbar-brand">
              <img src={logoSmall} alt="logo" height="28" />          
            </a>
    
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
    
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav col-12 d-flex flex-row-reverse">
                
                <li className="nav-item">            
                  <Logout />
                </li>
                
                <li className="nav-item" id="btn-campanha" style={{marginRight: '10px'}}>
                  <Campanha />
                </li>    

                <li className="nav-item" id="btn-home" style={{marginRight: '10px'}}>
                  <HomeVoltar />
                </li>                   
                
              </ul>
            </div>    
          
        </div>
        
      </nav>
       
    )
}



export default Menu;