import React, { useEffect, useState } from "react";
import Menu from "../Menu";
import './index.css';
import CardBusca from "../CardBusca";
import { api } from '../../services/api';
import { API_URL } from "../../constants/constants.jsx";
import { useNavigate } from 'react-router-dom';


function Home(){
  const [busca, setBusca] = useState([]);
  const [texto, setTexto] = useState('');
  const [cidade, setTextoCidade] = useState('');
  const [pesquisa, setPesquisa] = useState('');
  const [cidadesel, setCidadeSel] = useState('');
  const [listacidades, setListaCidades] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    api.get(`${API_URL}/busca?userid=${localStorage.getItem('session_iduser')}`)
      .then(response => {
        setBusca(response.data);
      })
      .catch(error => {
        console.error('Erro ao carregar os dados:', error);
      });

      const btnCampanha = document.getElementById('btn-home');
    btnCampanha.style.display = 'none';

    const setWindowHeight = () => {
      document.documentElement.style.setProperty('--window-height', `${window.innerHeight-180}px`);
    };
    // Define a variável quando o componente monta
    setWindowHeight();
    // Atualiza a variável quando a janela é redimensionada
    window.addEventListener('resize', setWindowHeight);
    // Limpa o event listener quando o componente desmonta
    return () => {
      window.removeEventListener('resize', setWindowHeight);
    };

    
  }, []); // Array de dependências vazio para executar apenas uma vez, quando o componente for montado

  useEffect(() => {
    api.get(`${API_URL}/cidades?userid=${localStorage.getItem('session_iduser')}`)
      .then(response => {
        setListaCidades(response.data);
      })
      .catch(error => {
        console.error('Erro ao carregar as cidades:', error);
      });
  }, []);

  useEffect(() => {
    if (pesquisa && cidade) {
      // Corpo da requisição POST
      const postData = { Busca: pesquisa };
  
      // Fazer a requisição POST usando Axios
      api.post(`${API_URL}/busca`, postData, {
        headers: {
          'Content-Type': 'application/json',
          'busca': pesquisa, 
          'cidade': cidade,
          'userid': localStorage.getItem('session_iduser')
        }
      })
        .then(response => {
          return api.get(`${API_URL}/busca?userid=${localStorage.getItem('session_iduser')}`, {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
            params: { pesquisa } // Passando pesquisa como parâmetro de query na URL
          });
        })
        .then(response => {
          setBusca(response.data);
        })
        .catch(error => {
          console.error('Erro na requisição POST:', error);
        });

        // limpa variaveis
        let iramo = document.getElementById("inp-ramo");
        iramo.value ="";
        let icidade = document.getElementById("inp-cidade");
        icidade.value ="";
        setPesquisa('');
        setTexto('');
        setTextoCidade('');
    }
  }, [pesquisa,cidade]);

  const handleSelectChange = (event) => {

    setCidadeSel(event.target.value);   
     if (event.target.value === 'Todas as cidades') {
        api.get(`${API_URL}/busca?userid=${localStorage.getItem('session_iduser')}`)
          .then(response => {
            setBusca(response.data);
          })
          .catch(error => {
            console.error('Erro ao carregar os dados:', error);
          });
        
     } else {
      api.get(`${API_URL}/busca/cidade?userid=${localStorage.getItem('session_iduser')}&cidade=${event.target.value}`)
      .then(response => {
        setBusca(response.data);
      })
      .catch(error => {
        console.error('Erro ao carregar os dados:', error);
      });
      }    
  };
  
 return(<>

    <Menu btnCampanha={true} /> 
  
    {/* menu de pesquisa */}
    <div className="pagina-home">
     
      <div className="container-fluid">
        
         <div className="row">
           
           <div className="col-md-5">
             <h6>Busca empresas no google maps</h6>
           </div>
               
           <div className="col-md-7">
              <div className="input-group mb-3">
                 <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" id="inp-ramo" placeholder="Ramo" aria-describedby="button-addon2" style={{marginRight: '10px'}}></input> 
                 <input onChange={(e) => setTextoCidade(e.target.value)} type="text" className="form-control" id="inp-cidade" placeholder="Cidade" aria-describedby="button-addon3" ></input> 
                 <button onClick={(e) => setPesquisa(texto)} className="btn btn-outline-info" type="button" id="button-busca"><i className="fa fa-search" aria-hidden="true"></i>Lança Pesquisa</button>
              </div>
           </div>    
                
         </div>

         <div className="d-flex">

         <select onChange={handleSelectChange} className="form-select form-select-sm" aria-label="Default select example" style={{marginBottom: '10px', maxWidth: '250px', cursor: 'pointer'}}>
            <option selected>Todas as cidades</option>
            {
              listacidades.map((cid) =>(
                <option value={cid.CIDADE}>{cid.CIDADE}</option>
              ))
            }           
            
         </select>

         </div>
     
         {/* listagem de busca */} 
         <div className="div-lista-empresas">
            <div className="row">         
              {
                busca.map((bus) => {           
                  /* sempre que utilizar o map deve ter o key={prod.codigo} para identificar a div */
                  return <div className="" key={bus.ID}>            
                            <CardBusca
                                id={bus.ID}
                                busca={bus.BUSCA} 
                                status={bus.STATUS}   
                                cidade={bus.CIDADE}   
                                total={bus.TOTAL}                             
                            />
                          </div> ;                  
                })
              }                
            </div>    
          </div>        
      </div>
      
    </div>
    </>
  )
}

export default Home;