import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Menu from '../Menu/index.jsx';
import './index.css';
import { API_URL } from "../../constants/constants.jsx";
import { mostrarMensagem } from '../../components/sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


const Campanha = () => {
  const [campanhas, setCampanhas] = useState([]);
  const [selectedCampanha, setSelectedCampanha] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [anexo, setAnexo] = useState(null);
  const [anexoNome, setAnexoNome] = useState('');

  useEffect(() => {
    fetchCampanhas();
    
    const btnCampanha = document.getElementById('btn-campanha');
    btnCampanha.style.display = 'none';

    const setWindowHeight = () => {
      document.documentElement.style.setProperty('--window-heightx', `${window.innerHeight-520}px`);
    };
    // Define a variável quando o componente monta   
    setWindowHeight();
    // Atualiza a variável quando a janela é redimensionada
    window.addEventListener('resize', setWindowHeight);
    // Limpa o event listener quando o componente desmonta
    return () => {
      window.removeEventListener('resize', setWindowHeight);
    };  

  }, []);

  const fetchCampanhas = async () => {
    try {
      const response = await axios.get(`${API_URL}/campanhas?userid=${localStorage.getItem('session_iduser')}`);
      setCampanhas(response.data);
    } catch (error) {
      console.error('Erro ao buscar campanhas:', error);
    }
  };

  const handleAddCampanha = async () => {
    if (descricao && mensagem){
      try {
        const response = await axios.post(`${API_URL}/campanhas/add?userid=${localStorage.getItem('session_iduser')}`, {
          descricao,
          mensagem
        });
        fetchCampanhas();
        mostrarMensagem('Sucesso!', 'Registro incluído com sucesso!', 'success');
      } catch (error) {
        console.error('Erro ao adicionar campanha:', error);
      }
    }    
  };

  const handleLimpar = async () => {
    let idescricao = document.querySelector(".e-descricao");
    let imensagem = document.querySelector(".e-mensagem");
    let ianexo = document.getElementById("e-anexo");
  
    if (idescricao) {
      idescricao.value = "";
    }
  
    if (imensagem) {
      imensagem.value = "";
    }

    if (ianexo) {
      ianexo.textContent = "";
    }
  
    setDescricao('');
    setMensagem('');
    setAnexoNome('');
  };
  

  const handleUpdateCampanha = async () => {
    if (!selectedCampanha) return;
    try {
      const response = await axios.put(`${API_URL}/campanhas/id?userid=${localStorage.getItem('session_iduser')}&id=${selectedCampanha.ID}`, {
        descricao,
        mensagem
      });
      fetchCampanhas();
      mostrarMensagem('Sucesso!', 'Registro alterado com sucesso!', 'success');
    } catch (error) {
      console.error('Erro ao atualizar campanha:', error);
    }
  };

  const handleDeleteCampanha = async () => {
    if (!selectedCampanha) return;
    try {
      await axios.delete(`${API_URL}/campanhas/id?userid=${localStorage.getItem('session_iduser')}&id=${selectedCampanha.ID}`);
      fetchCampanhas();
      mostrarMensagem('Sucesso!', 'Registro excluído com sucesso!', 'success');
    } catch (error) {
      console.error('Erro ao excluir campanha:', error);
    }
  };

  const handleFileUpload = async () => {
    if (!anexo || !selectedCampanha) return;
    const formData = new FormData();
    formData.append('anexo', anexo);
    try {
      const response = await axios.post(`${API_URL}/anexo/add?idcampanha=${selectedCampanha.ID}&nomeanexo=${anexo.name}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Upload de anexo concluído:', response.data);
      mostrarMensagem('Sucesso!', 'Upload realizado com sucesso!', 'success');
    } catch (error) {
      console.error('Erro ao fazer upload do anexo:', error);
    }
  };

  const handleFileDownload = async () => {
    if (!selectedCampanha) return;
    try {
      const response = await axios.get(`${API_URL}/anexo/download?idcampanha=${selectedCampanha.ID}`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', anexoNome || 'anexo');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Erro ao baixar anexo:', error);
    }
  };

  const handleFileDelete = async () => {
    // implementar a requisicao de exlusao do anexo
    let ianexo = document.getElementById("e-anexo");
    ianexo.textContent = '';

    setAnexoNome('');
    mostrarMensagem('Sucesso!', 'Anexo excluído com sucesso!', 'success');
  }

  const handleSelectCampanha = async (campanha) => {
    setSelectedCampanha(campanha);
    setDescricao(campanha.DESCRICAO);
    setMensagem(campanha.MENSAGEM);

    // Fetch the anexo name for the selected campanha
    try {
      const response = await axios.get(`${API_URL}/campanhas/id?userid=${localStorage.getItem('session_iduser')}&id=${campanha.ID}`);
      if (response.data[0]) {
        setAnexoNome(response.data[0].NOMEANEXO);
      } else {
        setAnexoNome('');
      }
    } catch (error) {
      console.error('Erro ao buscar nome do anexo:', error);
      setAnexoNome('');
    }
  };

  return (
    <>
      <Menu />
      <div className='container camp-card'>
        <div className='row  justify-content-center align-items-center'>
          <h1 className='camp-tt'>Cadastro de campanhas</h1>
          <span className='camp-titulo-span'>Descrição</span>
          <input className="form-control camp-titulo" id='e-descricao' autoComplete="off" type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} placeholder="Descrição" />
          <span className='camp-titulo-span'>Mensagem</span>
          <textarea className="form-control camp-descricao" id='e-mensagem' autoComplete="off" rows={10} value={mensagem} onChange={(e) => setMensagem(e.target.value)} placeholder="Mensagem" />
          <input type="file" id="fileInput" onChange={(e) => setAnexo(e.target.files[0])} style={{ marginBottom: '5px' }} />
          <div className='camp-anexoa'>
               {anexoNome ? (<>
                <a className='link-opacity-70' id='e-anexo' style={{marginBottom: '10px',marginLeft: '5px'}} onClick={handleFileDownload}>Anexo: {anexoNome} </a> 
                <a><FontAwesomeIcon icon={faTimes} style={{ marginLeft: '5px', cursor: 'pointer',height: '16px', color: 'red'}} onClick={handleFileDelete} /></a>
                </>
              ) : (
                <span className='span-ssemanexo' id='e-anexo' style={{marginBottom: '10px',marginLeft: '5px'}} >Sem Anexo</span>
              )}
          </div>
        </div>     
        
        <div className="campaign-list-container">
          <div className="campaign-list">
          {campanhas.map((campanha) => (
            <div className="card camp-card-lista" key={campanha.ID} onClick={() => handleSelectCampanha(campanha)}>
              <div className="d-flex">
                <div className="img-foto-b d-flex justify-content-end align-items-center">
                  <img className="camp-card-ftt" alt="Carro" src="https://static.vecteezy.com/system/resources/previews/000/584/801/original/check-mark-icon-logo-template-illustration-design-vector-eps-10.jpg" />
                </div>
                <div className="col">
                  <h6 className="card-busca">{campanha.DESCRICAO}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
       
      </div>

      <div className='row'>
        
          <div className="btn-group" role="group" aria-label="acoes" style={{marginLeft: '10px'}}>
            <button onClick={handleLimpar} type="button" className="btn btn-primary camp-btn-acoes">Novo</button>
            <button onClick={handleAddCampanha} type="button" className="btn btn-success camp-btn-acoes">Adicionar</button>
            <button onClick={handleUpdateCampanha} type="button" className="btn btn-warning camp-btn-acoes">Alterar</button>
            <button onClick={handleDeleteCampanha} type="button" className="btn btn-danger camp-btn-acoes">Excluir</button>
            <button onClick={handleFileUpload} type="button" className="btn btn-info camp-btn-acoes">Upload</button>
          </div>
        </div>

      </div>
    </>
  );
};

export default Campanha;
