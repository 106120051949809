import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/login';
import Campanha from './components/Campanha';
import PrivateRoute from './components/privateRoute';

function Rotas(){
    return <>
        
        <BrowserRouter>  
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route exact path="/login" element={<Login />} />         
                <Route exact path="/campanha" element={<Campanha />} />                   
            </Routes>
        </BrowserRouter>
    </>
}

export default Rotas;