// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagina-home{
   margin-top: 70px; 
}

.div-lista-empresas{
  height: var(--window-height);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/index.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;AACnB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".pagina-home{\r\n   margin-top: 70px; \r\n}\r\n\r\n.div-lista-empresas{\r\n  height: var(--window-height);\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
