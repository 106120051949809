import Swal from 'sweetalert2';

// Função para mostrar mensagens com parâmetros personalizados
export const mostrarMensagem = (titulo, texto, icone, timer = 3000) => {
  Swal.fire({
    title: titulo,
    text: texto,
    icon: icone,
    showConfirmButton: false,
    timer: timer,
    showConfirmButton: 'OK'
  });
};
