import React, { useState } from "react";
import './index.css';
import { api } from '../../services/api';
import CardEmp from "../CardEmpresa";
import * as XLSX from 'xlsx'; 
import { API_URL } from "../../constants/constants.jsx";
import { mostrarMensagem } from '../../components/sweetalert';

function CardBusca(props) {  
  const [showModal, setShowModal] = useState(false);
  const [lista, setLista] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [ramo, setRamo] = useState('');
  const [cidade, setCidade] = useState('');

  let statusClass;
  switch (props.status) {
    case 'Finalizado':
      statusClass = "status-button-finalizado";
      break;
    case 'Lançado':
      statusClass = "status-span-lancado";
      break;
    case 'Pesquisando':
      statusClass = "status-span-pesquisando";
      break;
    default:
      statusClass = "status-span-default";
  }

  const handleModal = () => {
    setShowModal(true);
    if (!loaded) {
      api.get(`${API_URL}/pesq?id=${props.id}`)
        .then(response => {
          setLista(response.data);
          setRamo(props.busca);
          setCidade(props.cidade);
          setLoaded(true);
        })
        .catch(error => {
          console.error('Erro ao carregar os dados:', error);
        });
    }
  };

  const handleRefresh = () => {    
    if (!loaded) {
      api.delete(`${API_URL}/busca/refresh?idbusca=${props.id}`)
        .then(response => {          
          setLoaded(true);
          window.location.reload();
          mostrarMensagem('Sucesso!', 'Solicitação de nova pesquisa enviada com sucesso', 'success');
        })
        .catch(error => {
          console.error('Erro ao carregar os dados:', error);
        });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(lista);
    
    // Ajustando a largura das colunas automaticamente
    const wscols = [];
    Object.keys(lista[0]).forEach(key => {
      const maxColumnLength = lista.reduce((acc, obj) => {
        const cellValue = obj[key] !== null && obj[key] !== undefined ? obj[key].toString() : ''; // Verificar se o valor é null ou undefined
        const cellLength = cellValue.length;
        return cellLength > acc ? cellLength : acc;
      }, 0);
      wscols.push({ wch: maxColumnLength + 2 }); // Adiciona 2 caracteres extras de margem
    });

    ws['!cols'] = wscols;
    
    XLSX.utils.book_append_sheet(wb, ws, "Dados");
    XLSX.writeFile(wb, `'${ramo}_${cidade}.xls'`);
  };
  
  return (    
    <div className="card card-b" style={{ height: 'auto' }}>
      <div className="d-flex">
        <div className="img-foto-b d-flex justify-content-end align-items-center">
            <img className="card-ftt" alt="Carro" src="https://static.vecteezy.com/system/resources/previews/000/584/801/original/check-mark-icon-logo-template-illustration-design-vector-eps-10.jpg" />
        </div>
        <div className="col">
          <h6 className="card-busca">{props.busca}  <span className="badge" style={{backgroundColor: '#e3f2fd', color: 'black'}}> {props.total}</span></h6>  
          <h6 className="card-cidade">{props.cidade}</h6>  
              
          </div>
        <div className="card-bb d-flex justify-content-end align-items-center">          
          <>      
          {props.status === 'Finalizado' ? (
            <button onClick={handleModal} className={"status-button " + statusClass}>
              <i className="fas fa-map-marker-alt"></i>{props.status}
            </button>
          ) : (
            <span className={"status-span " + statusClass}>
              <i className="fas fa-map-marker-alt"></i>{props.status}
            </span>
          )}
          </>      
        </div>

        <div className="card-bb d-flex justify-content-end align-items-center">          
          <>      
          {props.status === 'Finalizado' && (
            <button onClick={handleRefresh} className="btn-refresh status-span-default">
              <i className="fas fa-map-marker-alt"></i>Refresh
            </button>
          )}
          </>      
        </div>
      </div>

      {showModal && (
        <div className="modal" style={{ display: 'block', overflowX: 'hidden' }}>
          <div className="modal-content" style={{ overflowX: 'hidden' }}>
            <div>

              <span>Ramo: {ramo} </span>
              <br></br>
              <span>Total: ({lista.length} - registros)</span>
              <button className="close-button" onClick={handleCloseModal}>
                    <img src="https://th.bing.com/th/id/OIP.w5PjY6HlhojxS0PXPdBRfgAAAA?rs=1&pid=ImgDetMain" alt="Close" />
              </button>
              <hr></hr>
              
              <div className="d-flex justify-content-end mb-3"> {/* Div para alinhar o botão à direita */}
                <button className="btn btn-outline-success" onClick={exportToExcel}>Exportar p/ Excel</button>
              </div>

              <div className="row">         
                {
                    lista.map((emp) => {           
                    return <div className="" key={emp.ID}>            
                                <CardEmp
                                    id={emp.ID}
                                    nome={emp.NOME}
                                    foto={emp.FOTO}
                                    avaliacao={emp.AVALIACAO}
                                    endereco={emp.ENDERECO}
                                    status={emp.STATUS}
                                    fone={emp.FONE}
                                    fone2={emp.FONE2}
                                    href={emp.HREF}
                                    opcoes={emp.OPCOES}     
                                    ramo={emp.RAMO}    
                                    stwpp={emp.STWPP}
                                    dtwpp={emp.DTWPP}               
                                />
                            </div> ;                  
                    })
                }                
              </div>              
              
            </div>
            <button className="btn btn-outline-danger" onClick={handleCloseModal}>Fechar</button>
          </div>
          
        </div>
      )}
    </div>
  );
}

export default CardBusca;
