import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { api } from '../../services/api';
import { API_URL } from "../../constants/constants.jsx";

function Login(){

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();  // Hook do React Router para navegação

    function ProcessaLogin(e){
        
        e.preventDefault();

        setSucesso('');
        setLoading(true);        

        api.post(`${API_URL}/v1/login`, {
            email: email,
            senha: senha    
                
        })
        .then(response => {        
            console.log('Resposta completa:', JSON.stringify(response.data, null, 2));  // Converte o objeto response.data para uma string JSON formatada

            localStorage.setItem('session_iduser', response.data[0].ID);
            localStorage.setItem('session_usuario', response.data[0].USUARIO);
                   
            setSucesso('S');
            setLoading(false);
            navigate('/home');

        })
        .catch(err => {
            setSucesso('N');
            setLoading(false);
        })
    }

    return <div className="container h-100">
    <div className="row h-100 justify-content-center align-items-center">
        <div className="col-sm-6">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <form className="form-login mt-5">
                    <img src={Logo} alt="DT Empresas" className="mt-5" style={{width: '300px'}}/>

                    <br></br>
 
                    <h6 className="mb-3 mt-5">Acesse sua conta</h6>                        

                    <div className="form-floating">
                        <input type="email" onChange={(e) => setEmail(e.target.value)} autoComplete="off" className="form-control" id="floatingInput" placeholder="E-mail" />
                        <label htmlFor="floatingInput">E-mail</label>
                        <p></p>
                    </div>

                    <div className="form-floating">
                        <input type="password" onChange={(e) => setSenha(e.target.value)} autoComplete="off"  className="form-control" id="floatingInput" placeholder="Senha" />
                        <label htmlFor="floatingInput">Senha</label>
                    </div>
                    <br></br>
                    <button onClick={ProcessaLogin} className="w-100 btn btn-lg btn-primary mt-3" disabled={loading} style={{backgroundColor: "#162e44", borderTopColor: "#162e44"}}>
                        {loading ? <div>
                                    <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                    <span className="ms-2">Enviando...</span>
                                    </div> : <span className="ms-2">Acessar</span>
                        }
                    </button>

                    {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">E-mail ou senha inválida</div> : null}
                    
                </form>    
            </div>
        </div>
    </div>
</div>


}

export default Login;