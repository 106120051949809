// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-codigo-e{
    color: rgb(139, 140, 153);
    height: 16px;
    overflow: hidden;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 2px;
}

.card-foto-e{
    object-fit: cover;
    display: flex;
    align-items: center; /* Centraliza verticalmente */
    justify-content: center; /* Centraliza horizontalmente */
    height: 80px; /* Garante que a imagem ocupe toda a altura do container */
    width: 68px;
    border-radius: 5px;
}

.img-foto-e{
    margin: 5px;
}

.card-body-e{
    margin-left: 5px;
    margin-bottom: 0px;
    margin-top: 5px;    
}

.card-title-e{
    font-size: 14px;
}

.card-e{
    max-height: 100px;
    padding: 0;
    margin-bottom: 5px;
}

.card-status{
    color: rgb(139, 140, 153);
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardEmpresa/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB,EAAE,6BAA6B;IAClD,uBAAuB,EAAE,+BAA+B;IACxD,YAAY,EAAE,0DAA0D;IACxE,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".card-codigo-e{\r\n    color: rgb(139, 140, 153);\r\n    height: 16px;\r\n    overflow: hidden;\r\n    font-size: 12px;\r\n    margin-top: 0px;\r\n    margin-bottom: 2px;\r\n}\r\n\r\n.card-foto-e{\r\n    object-fit: cover;\r\n    display: flex;\r\n    align-items: center; /* Centraliza verticalmente */\r\n    justify-content: center; /* Centraliza horizontalmente */\r\n    height: 80px; /* Garante que a imagem ocupe toda a altura do container */\r\n    width: 68px;\r\n    border-radius: 5px;\r\n}\r\n\r\n.img-foto-e{\r\n    margin: 5px;\r\n}\r\n\r\n.card-body-e{\r\n    margin-left: 5px;\r\n    margin-bottom: 0px;\r\n    margin-top: 5px;    \r\n}\r\n\r\n.card-title-e{\r\n    font-size: 14px;\r\n}\r\n\r\n.card-e{\r\n    max-height: 100px;\r\n    padding: 0;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.card-status{\r\n    color: rgb(139, 140, 153);\r\n    font-size: 12px;\r\n    margin-top: 0px;\r\n    margin-bottom: 5px;\r\n    padding-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
